<template>
    <div class="entfreespace_container">
        <img class="entfreespace_lock" src="/media/img/player/lock.png" alt="lock.png" />
        <h1 class="entfreespace_title" v-if="usertype === 3">
            지금, 내 공간에 어울리는 <br />
            음악을 무제한으로 추천받아보세요.
        </h1>
        <h1 class="entfreespace_title" v-if="usertype === 5">
            스페이스 정보를 등록하면 어울리는 <br />
            음악을 추천해드려요.
        </h1>
        <div class="subscribe_btn_wrap">
            <button class="entfreespace_subscribe_btn current_subscribe" @click="currentSpaceClick">
                {{ newSubScribeText }}
            </button>
            <button class="entfreespace_subscribe_btn newspace_subscribe" v-if="usertype === 3" @click="newspaceSubscribe">
                새로운 스페이스 구독하기
            </button>
        </div>
    </div>
</template>
<script>
export default {
  name: 'EndFreeSpace',
  props: {
    usertype: {
      type: Number,
      required: false,
      default: 3
    }
  },
  emits: ['currentspace-subscribe', 'newspace-subscribe'],
  data () {
    return {
      currentSpaceClick: () => {}
    };
  },
  created () {
    if (this.usertype === 3) {
      this.currentSpaceClick = this.currentSpaceSubscribe;
    } else {
      this.currentSpaceClick = this.newspaceSubscribe;
    }
  },

  mounted () {},

  methods: {
    currentSpaceSubscribe () {
      this.$emit('currentspace-subscribe');
    },
    newspaceSubscribe () {
      this.$emit('newspace-subscribe');
    }
  },
  computed: {
    newSubScribeText () {
      if (this.usertype === 3) {
        return '현재 스페이스 구독하기';
      } else if (this.usertype === 5) {
        return '스페이스 등록하기';
      }
      return '';
    }
  }
};
</script>
<style scoped src="@/assets/css/player/endfreespace.css"></style>
